import React, { useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highmaps";
import mapDataUzbekistan from "@highcharts/map-collection/countries/uz/uz-all.geo.json";
const UzbekistanMap = () => {
    const [hoverData, setHoverData] = useState(null);
    const [chartOptions, setChartOptions] = useState({
        chart: {
            map: mapDataUzbekistan
        },
        mapNavigation: {
            enabled: true,
            buttonOptions: {
                verticalAlign: "bottom",
            },
        },
        credits: {
            enabled: false, // Bu joyda pastdagi "Highcharts.com" va "Natural Earth" kreditlarini o'chiramiz
        },
        legend: {
            enabled: false, // Bu joyda legendni butunlay o'chirib qo'yamiz
        },
        colorAxis: {
            min: 0,
        },
        series: [
            {
                name: "Random data",
                states: {
                    hover: {
                        color: "#BADA55"
                    }
                },
                dataLabels: {
                    enabled: true,
                    format: "{point.name}"
                },
                allAreas: false,
                data: [
                    { "hc-key": "uz-fa", value: 10, color: "#FF5733" }, // Farg'ona viloyati uchun rang
                    { "hc-key": "uz-tk", value: 11, color: "#33FF57" }, // Toshkent shahri uchun rang
                    { "hc-key": "uz-an", value: 12, color: "#3357FF" }, // Andijon viloyati uchun rang
                    { "hc-key": "uz-ng", value: 13, color: "#FF33A5" }, // Namangan viloyati uchun rang
                    { "hc-key": "uz-ji", value: 14, color: "#FF8C33" }, // Jizzax viloyati uchun rang
                    { "hc-key": "uz-si", value: 15, color: "#FFC300" }, // Sirdaryo viloyati uchun rang
                    { "hc-key": "uz-ta", value: 16, color: "#DAF7A6" }, // Toshkent viloyati uchun rang
                    { "hc-key": "uz-bu", value: 17, color: "#900C3F" }, // Buxoro viloyati uchun rang
                    { "hc-key": "uz-kh", value: 18, color: "#581845" }, // Xorazm viloyati uchun rang
                    { "hc-key": "uz-qr", value: 19, color: "#C70039" }, // Qoraqalpog‘iston Respublikasi uchun rang
                    { "hc-key": "uz-nw", value: 20, color: "#FFC300" }, // Navoiy viloyati uchun rang
                    { "hc-key": "uz-sa", value: 21, color: "#FF5733" }, // Samarqand viloyati uchun rang
                    { "hc-key": "uz-qa", value: 22, color: "#C70039" }, // Qashqadaryo viloyati uchun rang
                    { "hc-key": "uz-su", value: 23, color: "#581845" }, // Surxondaryo viloyati uchun rang
                ]
            }
        ]
    });
    const chartRef = React.useRef(null);


    function resetZoom() {
        const chart = chartRef.current.chart;
        if (chart) {
            chart.xAxis[0].setExtremes();
        }
    }

    function dataOne() {
        setChartOptions(data1);
        resetZoom();
    }

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
                constructorType={"mapChart"}
                updateArgs={[true, true, true]}
                ref={chartRef}
            />
        </div>
    );
};

export default UzbekistanMap;
